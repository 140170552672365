import React from "react"
import FollowUs from "./FollowUs"

const Sidebar = () => {
  return (
    <aside className="hidden lg:block lg:w-3/12 lg:pl-14">
      <FollowUs />
    </aside>
  )
}

export default Sidebar
