import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { IoCopy } from "react-icons/io5"

const Copy = ({ text, copytext }) => {
  const [copyText, setCopyText] = useState("copy")
  return (
    <CopyToClipboard text={copytext} onCopy={() => setCopyText("copied!")}>
      <li>
        {text}
        <span className="font-semibold inline-flex gap-2 cursor-pointer items-center">
          {copytext}

          <span className="text-sm font-normal text-green-700 h-full flex gap-0.5 items-center justify-center select-none">
            <IoCopy className=" text-green-600 mx-auto" />
            {copyText}
          </span>
        </span>
      </li>
    </CopyToClipboard>
  )
}

export default Copy
