import React from "react"

const Footer = () => {
  return (
    <footer className="bg-black py-4 md:py-6 text-white">
      <div className="container text-center font-semibold text-sm">
        Copyright © 2021 MaltaMarijuana.com
      </div>

      {/* <div className="container text-center flex flex-col gap-2 items-center  text-xs">
        <p>Copyright © 2021 MaltaMarijuana.com</p>
        <p>
          Malta Marijuana is reader-supported. When you buy through links on our
          site, we may earn an affiliate commission. Learn more.
        </p>
      </div> */}
    </footer>
  )
}

export default Footer
