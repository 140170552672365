import React from "react"
import PropTypes from "prop-types"

const ExLink = ({ url, text, follow, style }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel={`${!follow && "nofollow"} noopener noreferrer`}
      className={`${style ? "underline" : "nostyle"}`}
    >
      {text}
    </a>
  )
}

export default ExLink

ExLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  follow: PropTypes.bool,
  style: PropTypes.bool,
}

ExLink.defaultProps = {
  follow: false,
  style: true,
}
