import React from "react"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  // FaInstagramSquare,
  FaPinterestSquare,
} from "react-icons/fa"

const FollowUs = () => {
  return (
    <section>
      {/* <p className="sidebar-title">Follow Us</p> */}
      <div className="social-icons">
        {/* <a
          href="https://www.facebook.com/MaltaMarijuana"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebookSquare alt="Facebook" />
        </a> */}
        {/* <a href="#" target="_blank" rel="noopener noreferrer">
          <FaInstagramSquare />
        </a> */}
        {/* <a
          href="https://twitter.com/SMMammoth"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        >
          <FaTwitterSquare alt="Twitter" />
        </a> */}

        {/* <a
          href="https://www.pinterest.com/socialmediamammoth"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Pinterest"
        >
          <FaPinterestSquare alt="Pinterest" />
        </a> */}
      </div>
    </section>
  )
}

export default FollowUs
