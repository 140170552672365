import * as React from "react"
import { IoMenu, IoSearch } from "react-icons/io5"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ isRootPath }) => {
  let title
  if (isRootPath) {
    title = (
      <h1 className="text-2xl">
        <Link to="/" title="Malta Marijuana">
          <StaticImage
            src="../images/logo.png"
            loading="eager"
            alt="Malta Marijuana"
            placeholder="tracedSVG"
          />
        </Link>
      </h1>
    )
  } else {
    title = (
      <Link to="/" title="Malta Marijuana">
        <StaticImage
          src="../images/logo.png"
          loading="eager"
          alt="Malta Marijuana"
          placeholder="tracedSVG"
        />
      </Link>
    )
  }
  return (
    <header className="bg-black py-6 md:py-8 text-white">
      <div className="container flex justify-between">
        {/* Title */}
        {title}

        {/* Menu Icons */}
        <section className=" flex gap-4 items-center">
          {/* <IoSearch className="text-2xl" />
          <IoMenu className="text-3xl" /> */}
        </section>
      </div>
    </header>
  )
}

export default Header
