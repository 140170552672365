import * as React from "react"
import Footer from "./Footer"
import { MDXProvider } from "@mdx-js/react"

import Header from "./header"
import Sidebar from "./Sidebar"
import Copy from "./Copy"
import ExLink from "./ExLink"

const shortcodes = { Copy, ExLink }

const Layout = ({ children, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  return (
    <div className="flex flex-col min-h-screen">
      <Header isRootPath={isRootPath} />

      <MDXProvider components={shortcodes}>
        <div
          className={`block lg:flex py-6 md:py-8 flex-1 ${
            !isRootPath && "container"
          }`}
        >
          <main className={`w-full  ${!isRootPath && "lg:w-9/12"}`}>
            {children}
          </main>
          {!isRootPath && <Sidebar />}
        </div>
      </MDXProvider>
      <Footer />
    </div>
  )
}

export default Layout
